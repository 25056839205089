<template>
  <div class="admin-customers-listing">
    <title-bar
      addBtn
      exportBtn
      :addUrl="getAddEmployeeURL()"
      title="Employees"
    />
    <search class="mb-4" @inputChange="inputChange" />
    <vue-good-table
      class="custom-table-style possible-vertical-align"
      mode="remote"
      :totalRows="pages"
      :isLoading.sync="isLoading"
      :columns="columns"
      :rows="employees"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
        setCurrentPage: currentPage,
      }"
      @on-page-change="onPageChange"
    >
      <template slot="table-row" slot-scope="props">
        <span
          :class="props.column.field"
          v-if="props.column.field == 'courier-name'"
        >
          <div class="name">
            <div v-if="props.row.fileUrl" class="courier-photo">
              <img
                :src="`${props.row.courier.fileUrl}`"
                :alt="`${props.row.givenName} ${props.row.familyName}`"
              />
            </div>
            <span>{{ props.row.givenName }}</span>
          </div>
        </span>

        <span
          class="d-flex align-items-center"
          :class="props.column.field"
          v-else-if="props.column.field == 'telephone'"
        >
          {{ props.formattedRow[props.column.field] }}
          <call-btn
            class="ml-2"
            v-if="props.formattedRow[props.column.field]"
            :number="props.formattedRow[props.column.field]"
          />
        </span>

        <span v-else-if="props.column.field == 'role'">
          <custom-select
            :passedParentObject="props.row"
            @changeWithParent="roleChanged"
            v-model="props.formattedRow[props.column.field]"
            :options="roles"
          />
        </span>

        <span v-else-if="props.column.field == 'enabled'">
          <b-badge :variant="props.row.enabled ? 'success' : 'danger'"
            >{{ props.row.enabled ? 'ENABLED' : 'DISABLED' }}
          </b-badge>
        </span>

        <span class="buttons" v-else-if="props.column.field == 'id'">
          <div class="just-buttons">
            <edit-btn
              :path="getEditEmployeeURL(props.formattedRow[props.column.field])"
            />
            <delete-btn @pressDelete="deleteCourier(props.row['@id'])" />
          </div>
        </span>

        <span :class="props.column.field" v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <template slot="pagination-bottom" slot-scope="props">
        <table-pagination
          v-model="currentPage"
          :total="pages"
          :per-page="perPage"
          :pageChanged="props.pageChanged"
          :perPageChanged="props.perPageChanged"
        />
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import TitleBar from '@/components/TitleBar.vue'
import TablePagination from '@/components/Table/TablePagination.vue'
import Search from '@/components/Inputs/Search.vue'
import CallBtn from '@/components/Buttons/CallBtn.vue'
import EditBtn from '@/components/Buttons/EditBtn.vue'
import DeleteBtn from '@/components/Buttons/DeleteBtn.vue'
import CustomSelect from '@/components/Inputs/CustomSelect.vue'
import Toast from '@/components/Toast.vue'
import ListUtils from '@/mixins/ListUtils'
import 'vue-good-table/dist/vue-good-table.css'

export default {
  name: 'AdminCustomersListing',
  components: {
    TitleBar,
    Search,
    VueGoodTable,
    TablePagination,
    CallBtn,
    CustomSelect,
    EditBtn,
    DeleteBtn,
  },
  mixins: [ListUtils],
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      search: '',
      isLoading: false,
      perPage: 15,
      currentPage: 1,
      pages: 0,
      enabledRoles: ['ROLE_STOCK_MANAGER', 'ROLE_DISPATCHER'],
      roles: [],
      employees: null,

      columns: [
        {
          field: 'courier-name',
          sortable: false,
        },
        {
          field: 'telephone',
          sortable: false,
        },
        {
          field: 'role',
          sortable: false,
        },
        {
          field: 'enabled',
          sortable: false,
        },
        {
          field: 'id',
          sortable: false,
          width: '100%',
        },
      ],
    }
  },
  created() {
    this.enabledRoles.forEach(role => {
      this.roles.push({
        label: this.$helper.getEnumTranslation('roles', role),
        id: role,
      })
    })
  },
  methods: {
    loadEmployees() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        role: this.enabledRoles,
        'order[enabled]': 'desc'
      }
      if (this.search && this.search.length) {
        params.search = this.search
      }
      this.$Users.getCollection({ params }, 'employee_list').then(response => {
        this.pages = response.data['hydra:totalItems']
        this.employees = response.data['hydra:member']
        this.employees.forEach(employee => {
          employee.role = {
            label: this.$helper.getEnumTranslation('roles', employee.role),
            id: employee.role,
          }
        })
      })
    },
    deleteCourier(url) {
      this.$Users
        .deleteResourceByUrl({ url })
        .then(response => {
          if (response.status === 204) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              'Employee deleted',
              '',
              'success',
            )
            this.loadEmployees()
          }
        })
        .catch(error => {
          if (error.response) {
            this.$emit('clearAction')
            this.$helper.showToast(
              this.$toast,
              Toast,
              'Failed to delete',
              '',
              'danger',
            )
          }
        })
    },
    roleChanged({ item, parent }) {
      const body = {
        role: item.id,
      }
      this.$Users
        .updateResourceByUrl({ url: parent['@id'], body })
        .then(response => {
          if (response.status === 200) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              'User role changed',
              '',
              'success',
            )
          }
        })
        .catch(error => {
          if (error.response) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              'Failed to change role',
              '',
              'danger',
            )
          }
        })
    },
    inputChange(value) {
      this.search = value
      this.loadEmployees()
    },
    onPageChange() {
      this.loadEmployees()
    },
    getAddEmployeeURL() {
      return `/add-employee`
    },
    getEditEmployeeURL(id) {
      return `/employees/${id}/edit`
    },
  },
}
</script>
